import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NetTable from 'components/Web_User_Interface/720p_Series/Network/IP_Configuration/netTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // IP Configuration",
  "path": "/Web_User_Interface/720p_Series/Network/IP_Configuration/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "You have two ways to access your camera over the internet - the easy way is called P2P. The only way to get complete access to all your camera´s functions is called DDNS. The DDNS or Internet Address gives you access to your cameras web user interface.",
  "image": "./WebUI_720p_SearchThumb_Network_IP_Configuration.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Network_IP_Configuration.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Network Menu // IP Configuration' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='You have two ways to access your camera over the internet - the easy way is called P2P. The only way to get complete access to all your camera´s functions is called DDNS. The DDNS or Internet Address gives you access to your cameras web user interface.' image='/images/Search/WebUI_720p_SearchThumb_Network_IP_Configuration.png' twitter='/images/Search/WebUI_720p_SearchThumb_Network_IP_Configuration.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Netzwerk/IP_Konfiguration/' locationFR='/fr/Web_User_Interface/720p_Series/Network/IP_Configuration/' crumbLabel="IP Config" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "network-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#network-menu",
        "aria-label": "network menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network Menu`}</h2>
    <h3 {...{
      "id": "ddns",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ddns",
        "aria-label": "ddns permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DDNS`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`Change your cameras IP address, gateway and DNS server - all these settings are usually set up by the `}<a href="http://install.instar.de/" target="_blank" rel="noopener noreferrer">{`Installation Wizard`}</a>{` and don´t need to be changed. The camera´s http port might have to be changed to access your cameras webUI `}<a to="/Internet_Access/The_DDNS_Service/">{`over the internet`}</a>{`. The RTSP port is the camera´s `}<a to="/Software/Mobile_Apps_HTTPS_and_RTSP/">{`RTSP Streaming Port`}</a>{`.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8df40f1732afe309fa1903e59c983f04/6bbf7/WebUI-Network_IP-Config.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsSAAALEgHS3X78AAACx0lEQVQ4y4XTf2+aQBgHcN/ZtnexZT9eSF/C9u+WTF20gGiTFrFtjHFqsd00OLmjWCjiQA4ox4ETpU3/7jJmVu3s9vkLLnzzPPccl+F5/vmLl0+ePnv1+k0xxbJssVgsFAq7xSLDlHZ3dxmGyeVy2WyWoqhSqVQulz+lMvV6Pfsx+/btO4qiFEXRVFW9UFVV/fWsaZPJxEjpun6paY7j5HK5nZ0dURS73W6mUimXGIbjuG63a9u2pv2KG8ZYUZSJMUmSZPnHYnFzc2Pbdj6fVxQFQpgpl8u5fI7jOEEQHMdByJlaUzRFlmkhhMIwjNbMZjNCyPHxMQBAkqTM/v7+h/cfaJputdskCL5PJuPxOAzD+TyO48UDy+WSEMLz/CrcabcZmuF5XhTFKIosyzJN03Xd2Wy2iLfwfb9Wq63CISHXyc3d3d3t7W0Q4JEyGogDXdejR2CM78MEBxjj5TJJkiQIgvHYME0LY7yl6RTG+L7tVqvF8/zXL19lWfY8zzKt76Y5j+ONOa/ZqExRVHr6dL1e9zzvUtPOz88dxwnDkGyzEd6rVBiaOTg4aDQanudNDOPiQo2i6LHKGwOjKCqfzx8eHjabTdd1LdMKCEmSZP6IjcrVKre3V2FZtvn5s+d5tm2TgAQ4mM/n/x8YlKEgCGdnZzKEV1dXEMq6riPbDsPwxzYbbQuC0Gw2G41Gv98nhMyiWZIk19fXi8XWfyTeaFuSpHa7IwgCAMD3fcuyRqORkZ42su0fa7uN4ziKoo3KvV6v1Wp1Op3BYOD7vq7rruulX678vef7MITw9PS01+9DCHHqwU3418D6vb6Q+vZtiDFWVRUAAFMAgJOTE1EUZVn+s4IQOjo6WoVlKEtDCQAoAeC6bnqlkfMbQoZhTKdTZw1CiOO4VRgAMBAH0lASB2K1WqVpurSGZdkHr4VCoVarybI8HA5/AsaHDZxjxX6FAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8df40f1732afe309fa1903e59c983f04/e4706/WebUI-Network_IP-Config.avif 230w", "/en/static/8df40f1732afe309fa1903e59c983f04/d1af7/WebUI-Network_IP-Config.avif 460w", "/en/static/8df40f1732afe309fa1903e59c983f04/04ac8/WebUI-Network_IP-Config.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8df40f1732afe309fa1903e59c983f04/a0b58/WebUI-Network_IP-Config.webp 230w", "/en/static/8df40f1732afe309fa1903e59c983f04/bc10c/WebUI-Network_IP-Config.webp 460w", "/en/static/8df40f1732afe309fa1903e59c983f04/d8378/WebUI-Network_IP-Config.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8df40f1732afe309fa1903e59c983f04/81c8e/WebUI-Network_IP-Config.png 230w", "/en/static/8df40f1732afe309fa1903e59c983f04/08a84/WebUI-Network_IP-Config.png 460w", "/en/static/8df40f1732afe309fa1903e59c983f04/6bbf7/WebUI-Network_IP-Config.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8df40f1732afe309fa1903e59c983f04/6bbf7/WebUI-Network_IP-Config.png",
              "alt": "Web User Interface - 720p Series - Network IP Configuration",
              "title": "Web User Interface - 720p Series - Network IP Configuration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can set whether the camera will be assigned an IP address by your router (DHCP) or, if you want to manually set a fixed address. Please check here on how to set a fixed IP address. In general, we recommend that you deactivate the automatic service and set a manual IP to avoid further trouble with the remote access to your camera. For the RTSP Port please refer to RTSP Streaming for details on the use of the camera´s RTSP video stream. Remember to forward the RTSP Port if you want to access the RTSP Stream over the internet with your DDNS Address. The RTMP Port is used by the Flash plugin in your camera´s web user interface. Remember to forward the RTMP Port (alongside your LAN Port!) if you want to access the web user interface over the internet with your DDNS Address.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <NetTable mdxType="NetTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      